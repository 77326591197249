import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';
import {
  BUTTON_BG_COLORS,
  BUTTON_SIZES,
} from '../../Buttons/RoundedButton/RoundedButton.constants';

import classes from './Content404.module.scss';

const Content404 = () => (
  <div className={classes.Content404}>
    <div className={classes.Content404__inner}>
      <div className={classes.Content404__div}>
        <h1>Page not found</h1>
        <div className={classes.Content404__buttonHolder}>
          <RoundedButton
            content={{
              label: 'Take me back to home page',
              slug: '/',
              backgroundColor: BUTTON_BG_COLORS.YELLOW,
              withBorder: false,
              size: BUTTON_SIZES.REGULAR,
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default Content404;
