import Head from 'next/head';

import { getSharedData } from '~/utils/api';

import Content404 from '../components/Content/Content404/Content404';
import MainPageFrame from '../components/Layouts/MainPageFrame/MainPageFrame';

const Custom404 = ({ sharedData }) => (
  <>
    <Head>
      <title key="title">BugBug.io | Page not found</title>
    </Head>
    <MainPageFrame sharedData={sharedData}>
      <Content404 />
    </MainPageFrame>
  </>
);

export async function getStaticProps() {
  const sharedData = await getSharedData();

  return {
    props: {
      sharedData,
    },
  };
}

export default Custom404;
